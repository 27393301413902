<template>
  <div class="account" v-loading="loading">
    <div class="header">
      <divider-title text="账户信息" />
      <span class="label">*为必填项</span>
    </div>
    <div class="container">
      <div class="base-info">
        <div class="base-item">
          <div>
            <span>姓氏*</span>
            <input class="input" type="text" v-model="baseInfo.name" @blur="blurAction('name')" />
          </div>
          <i class="icon el-icon-check" v-show="validateResult.name"></i>
        </div>
        <div class="base-item">
          <div>
            <span>名字*</span>
            <input class="input" type="text" v-model="baseInfo.nick" @blur="blurAction('nick')" />
          </div>
          <i class="icon el-icon-check" v-show="validateResult.nick"></i>
        </div>
        <div class="base-item">
          <div>
            <span>称谓*</span>
            <el-radio-group class="radio" v-model="baseInfo.gender">
              <el-radio :label="1">先生</el-radio>
              <el-radio :label="2">女士</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="base-item">
          <div>
            <span>电子邮箱*</span>
            <input class="input" type="text" v-model="baseInfo.email" @blur="blurAction('email')" />
          </div>
          <i class="icon el-icon-check" v-show="validateResult.email"></i>
        </div>
        <div class="base-item">
          <div>
            <span>您的地区</span>
            <div class="select-box">
              <el-select
                class="select"
                v-model="provinceId"
                popper-class="account-popper"
                placeholder="请选择"
                @change="provinceChange"
                clearable
              >
                <el-option
                  v-for="item in provinceList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-select
                class="select"
                v-model="cityId"
                popper-class="account-popper"
                placeholder="请选择"
                @change="cityChange"
                clearable
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-select
                class="select"
                v-model="areaId"
                popper-class="account-popper"
                placeholder="请选择"
                @change="areaChange"
                clearable
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="other-info">
        <p>
          更多关于您的消息
          <br />伊莉丝诚邀您共享您的个人信息与偏好，以便于为您提供更好的服务体验。
        </p>
        <p>
          请您在此留下生日信息，
          <br />我们会有更多惊喜呈现给您
        </p>
        <p></p>

      <div class="s">
          
        <el-select v-model="year" placeholder="请选择" @change="getDayData(year,month)">
            <el-option
              v-for="item in yearData"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>

          <el-select v-model="month" placeholder="请选择" @change="getDayData(year,month)">
            <el-option
              v-for="item in monthData"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>

          <el-select v-model="day" placeholder="请选择">
            <el-option
              v-for="item in dayData"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
      </div>





      </div>
      <div class="explain">
        <p>
          此表格上的信息需要进行数字处理，以方便您的订单和服务需求。
          <br />伊莉丝钻石是唯一的数据接收方，您有权访问并修改有关您的个人信息，
          <br />如您希望行使此项权利并获取有关信息，请联系我们。
        </p>
        <div class="agreement">
          <i :class="{ 'el-icon-check': isAgree }" @click="agree"></i>
          <span>
            我已阅读并接受
            <i @click="showPolicy">《伊莉丝钻石隐私保护政策》</i>
          </span>
        </div>
        <div :class="{ btn: true, disabled: !isAgree }" @click="confirm">确认</div>
      </div>
    </div>
  </div>
</template>
<script>
import dividerTitle from "@/components/dividerTitle";
import { GET_MYINFO, SET_MYINFO } from "../../service/account";
import chinaCities from "../../common/city";

export default {
  name: "Account",
  components: { dividerTitle },
  data() {
    return {
      loading: false,
      isAgree: false,
      value1:'',
      baseInfo: {
        name: "",
        nick: "",
        gender: 1, //1男，2女，
        email: "",
        cityid: "",
        birthday: ""
      },
      provinceId: "",
      cityId: "",
      areaId: "",
      provinceList: chinaCities,
      cityList: [],
      areaList: [],
      rules: {
        lastName: { required: true, message: "请输入姓氏！" },
        firstName: { required: true, message: "请输入名字！" },
        email: { required: true, type: "email", message: "请输入邮箱！" }
      },
      validateResult: {
        lastName: false,
        firstName: false,
        email: false
      },



       year:"2020",
       month:"1",
       day:"1",
       yearData:[],
       monthData:[],
       dayData:[],



    };
  },
  computed: {},
  created() {
    this.loading = true;
    GET_MYINFO().then(res => {
      this.loading = false;
      let { name, nick, gender, email, cityid, birthday } = res.attachment;
      this.baseInfo = { name, nick, gender, email, cityid, birthday };
      this.returnData(res.attachment);
    });
    this.getYearMounthDay()
    this.getYearData()
    this.getMonthData()
  },
  methods: {

//获取当前时间的年月日
 getYearMounthDay(){
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    this.getDayData(year,month);
 },
//获取年份
// getYearData(data){
// let arr = new Array();
// for(let i = data - 60;i < data + 10; i ++){
//     arr.push(i)
// }
// this.yearData = arr;
// this.yearIndex = arr.indexOf(this.year);
// },

  getYearData(){
            var a = new Date()
            var year = a.getFullYear()
            for (var i =0;i<=80;i++){
                this.yearData[i] = year - i
            }
            // console.log(this.yearlist)
    },



//获取月份
 getMonthData(){
     let arr = new Array();
    for(let i = 1;i < 13; i ++){
        arr.push(i)
    }
    this.monthData = arr;
 },
 //计算日期
getDayData(year,month){
  let day;
  if (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12) {
     day = 31
  } else if (month == 4 || month == 6 || month == 11 || month == 9) {
     day = 30
  } else if (month == 2) {
      if (year % 4 == 0 && (year % 100 != 0 || year % 400 == 0)) { //闰年
        day = 29
      } else {
        day = 28
      }
  }
  let arr = new Array();
  for(let i = 1;i < day + 1; i ++){
      arr.push(i)
  }
  this.dayData = arr;
},

//年份变化时
yearChange(picker, value, index){
    this.year = value;
    this.getDayData(value,this.month);
},
//月份变化时
monthChange(picker, value, index){
    this.month = value;
    this.getDayData(this.year,value);
},





    returnData(res) {
      let [year, month, day] = res.birthdayStr.split("-");
      Object.assign(this, { year, month, day });
      let province = this.provinceList.find(provice => {
        return this.findCity(provice);
      });
      this.cityList = province.cities;
      this.areaId = this.baseInfo.cityid;
    },
    findCity(provice) {
      let cityid = this.baseInfo.cityid;
      return provice.cities.some(city => {
        if (city.areas.some(area => area.id === cityid)) {
          this.provinceId = city.pid;
          this.cityId = city.id;
          this.areaList = city.areas;
          return true;
        }
      });
    },
    blurAction(type) {
      let value = this.baseInfo[type];
      let rule = this.rules[type];
      this.validateResult[type] = !!(rule.required && value);

      if (rule.type === "email") {
        let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        this.validateResult[type] = reg.test(value);
      }
    },
    provinceChange(id) {
      this.cityList = [];
      this.cityId = "";
      this.cityChange();
      if (!id) {
        return;
      }
      this.cityList = this.provinceList.find(item => item.id === id).cities;
    },
    cityChange(id) {
      this.areaList = [];
      this.areaId = this.areaChange();
      if (!id) {
        return;
      }
      this.areaList = this.cityList.find(item => item.id === id).areas;
    },
    areaChange(id) {
      this.baseInfo.cityid = id;
    },
    agree() {
      this.isAgree = !this.isAgree;
    },
    showPolicy() {
      this.$router.push({ path: "/privacypolicy" });
    },
    confirm() {
      if (!this.isAgree) {
        return;
      }
      let { year, month, day } = this;
      this.baseInfo.birthday = [year, month, day].join("-");
      SET_MYINFO({ ...this.baseInfo }).then(res => {
        if (res.status === 200) {
          let user = this.$store.getters['user/getuserdata']
          console.log(user.nick)
          user.nick = this.baseInfo.nick
          this.$store.commit("user/SETUSERDATA", user);
          this.$message({
            type: "success",
            message: "操作成功"
          });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.account .explain .btn.disabled{
  cursor: text !important;
}
.account {
  background: #f5f5f5;
  box-shadow: 0px 4px 5px 2px #d6d6d6;
  .header {
    padding-top: 28px;
    height: 96px;
    background: #fff;
    position: relative;
    .label {
      position: absolute;
      right: 20px;
      bottom: 14px;
    }
  }
  .container {
    padding: 20px 52px 40px;
  }
  .base-info {
    padding: 40px 80px 0;
    background: #fff;
    box-shadow: 0px 4px 5px 2px #d6d6d6;
    .base-item {
      display: flex;
      align-items: center;
      padding-bottom: 40px;
      > div {
        width: 540px;
        display: flex;
        flex-direction: column;
        > span {
          color: #000;
          line-height: 36px;
          font-size: 18px;
        }
        .input {
          color: #444;
          font-size: 16px;
          height: 34px;
          line-height: 36px;
          border: 0;
          outline: 0;
          border-bottom: 1px solid #cfcfcf; /*no*/
          box-sizing: border-box;
        }
      }
      > .icon {
        margin-left: 35px;
        color: #952026;
        font-size: 18px;
      }
    }
    .select-box {
      display: flex;
      .select {
        width: 180px;
        margin-right: 28px;
      }
    }
  }
  .other-info {
    background: #fff;
    padding: 0 80px 40px;
    margin-top: 40px;
    box-shadow: 0px 4px 5px 2px #d6d6d6;
    > p {
      font-size: 18px;
      color: #666666;
    }
    > p:first-child {
      line-height: 40px;
      padding: 30px 0 26px;
    }
    > p:last-child {
      line-height: 28px;
    }
    > div {
      margin-top: 16px;
      input {
        width: 45px; /*no*/
        height: 36px;
        min-width: 30px; /*no*/
        line-height: 36px;
        font-size: 16px;
        text-align: center;
        border: none;
        border-bottom: 1px solid #cfcfcf; /*no*/
        margin-right: 7px;
        outline: none;
      }
    }
  }
  .explain {
    padding: 35px 16px 0px;
    > p {
      line-height: 30px;
      font-size: 17px;
      color: #999;
      margin-bottom: 16px;
    }
    .agreement {
      height: 32px;
      line-height: 32px;
      font-size: 20px;
      display: flex;
      > i {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #999; /*no*/
        line-height: 32px;
        text-align: center;
        margin-right: 14px;
        cursor: pointer;
        font-size: 28px;
        color: #0c56ab;
      }
      // > i.actived {
      // }
      > span {
        color: #676767;
        > i {
          font-style: normal;
          color: #0c56ab;
          cursor: pointer;
        }
      }
    }
    .btn {
      width: 138px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      color: #fff;
      background: #921d23;
      cursor: pointer;
      margin-top: 40px;
    }
    .btn.disabled {
      background: #aaa;
      cursor: not-allowed;
    }
  }
}
</style>
<style lang="less">
.account {
  .base-item {
    .radio {
      height: 34px;
      font-size: 16px;
      line-height: 34px;
      .el-radio__label {
        font-size: 16px;
      }
      .el-radio__input.is-checked + .el-radio__label {
        color: #333;
      }
      .el-radio__input.is-checked .el-radio__inner {
        border-color: #333;
        background: #333;
      }
      .el-radio__inner:hover {
        border-color: #333;
      }
    }
    .el-select .el-input__inner {
      height: 50px;
      font-size: 16px;
      border-radius: 0;
    }
    .el-select .el-input.is-focus .el-input__inner {
      border-color: #ccc;
    }
    .el-select .el-input__inner:focus {
      border-color: #ccc;
    }
    .el-input__icon {
      line-height: 30px;
    }
  }
}
.account-popper {
  .el-select-dropdown__item.selected {
    color: #000;
    font-weight: 700;
  }
}
.s{
  width: 100%;
  height: 50px;
}
.s .el-select /deep/ .el-input .el-input__inner{
  width: 100px;
  border: none;
}
</style>
